
































import Vue from 'vue';

import DnbWarning from '@/components/DnbWarning.vue';

export default Vue.extend({
  name: 'dnb-date-picker',
  components: { DnbWarning },
  props: {
    value: String,
    id: String,
    validationError: Array,
    disable: { type: Boolean, default: false },
    label: String,
  },
  computed: {
    dateValue(): string {
      return this.value?.split(' ')[0];
    },
    timeValue(): string {
      return this.value?.split(' ')[1];
    },
  },
  methods: {
    onInput() {
      // @ts-ignore
      const date = this.$refs.dateInput.value;
      // @ts-ignore
      const time = this.$refs.timeInput.value;

      if (date && time) {
        this.$emit('input', `${date} ${time}`);
      } else if (date) {
        this.$emit('input', date);
      } else {
        this.$emit('input', '');
      }
    },
  },
});
