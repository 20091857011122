






















































































































































import {
  ApiIncidentInputDto,
  ApiIncidentUpdateDto,
} from '@portals/shared/admin/ApiIncidentDto';
import { format } from 'date-fns';
import Vue from 'vue';

import AdminButtonGroup from '@/components/AdminButtonGroup.vue';
import AdminPopUp from '@/components/AdminPopUp.vue';
import AdminSection from '@/components/AdminSection.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DnbApiSelect from '@/components/DnbApiSelect.vue';
import DnbButton from '@/components/DnbButton.vue';
import DnbDatePicker from '@/components/DnbDatePicker.vue';
import DnbInput from '@/components/DnbInput.vue';
import DnbTextarea from '@/components/DnbTextarea.vue';
import DnbWarning from '@/components/DnbWarning.vue';
import {
  deleteApiIncident,
  fetchApiIncident,
  saveApiIncident,
} from '@/service/apiIncidentService';
import { scrollToError } from '@/utils';

import { Breadcrumb } from '../../models/Breadcrumb';

type Data = {
  loading: boolean;
  loaded: boolean;
  create: boolean;
  apiIncident: ApiIncidentInputDto;
  errors: Record<string, unknown>;
  updateErrors: Record<string, unknown>;
  errorMessage: string | undefined;
  displayPopUp: boolean;
};

const defaultIncident = {
  apiId: '',
  severity: 2,
  reference: 'manual',
  reportedAt: format(new Date(), 'YYYY-MM-DD HH:mm'),
  updates: [],
};

export default Vue.extend({
  name: 'edit-api-incident',
  components: {
    DnbTextarea,
    DnbApiSelect,
    DnbDatePicker,
    DnbInput,
    AdminSection,
    DnbButton,
    AdminButtonGroup,
    AdminPopUp,
    Breadcrumbs,
    DnbWarning,
  },
  props: {},
  data(): Data {
    return {
      loading: false,
      loaded: false,
      create: !this.$route.params.id,
      apiIncident: { ...defaultIncident, updates: [] },
      errors: {},
      updateErrors: {},
      errorMessage: undefined,
      displayPopUp: false,
    };
  },
  computed: {
    apiIncidentWithTimezone(): ApiIncidentInputDto {
      return {
        ...this.apiIncident,
        reportedAt: this.apiIncident.reportedAt
          ? new Date(this.apiIncident.reportedAt).toISOString()
          : '',
        resolvedAt: this.apiIncident.resolvedAt
          ? new Date(this.apiIncident.resolvedAt).toISOString()
          : undefined,
        updates: this.apiIncident.updates.map((update) => {
          return {
            ...update,
            reportedAt: update.reportedAt
              ? new Date(update.reportedAt).toISOString()
              : '',
          };
        }),
      };
    },
    breadcrumbs(): Breadcrumb[] {
      return [
        {
          name: 'Api Incidents',
          path: '/api-incidents',
        },
        {
          name: this.apiIncident.id || 'Create API Incident',
        },
      ];
    },
  },
  mounted() {
    this.loading = true;
    if (this.$route.params.id) {
      this.loadApiIncident(this.$route.params.id);
      this.loading = false;
    } else {
      this.apiIncident = { ...defaultIncident, updates: [] };
      this.loaded = true;
      this.loading = false;
    }
  },
  methods: {
    async loadApiIncident(id: string) {
      this.loaded = false;
      const incident = await fetchApiIncident(id);
      this.apiIncident = {
        ...incident,
        reportedAt: this.dateTimeFormatter(incident.reportedAt),
        resolvedAt: incident.resolvedAt
          ? this.dateTimeFormatter(incident.resolvedAt)
          : undefined,
        updates: incident.updates.map((update: ApiIncidentUpdateDto) => ({
          ...update,
          reportedAt: this.dateTimeFormatter(update.reportedAt),
        })),
      };
      this.loaded = true;
    },
    dateTimeFormatter(date: string) {
      return format(date, 'YYYY-MM-DD HH:mm');
    },
    addUpdate() {
      if (this.apiIncident.id) {
        this.apiIncident.updates.push({
          apiIncidentId: this.apiIncident.id,
          description: '',
          reportedAt: format(new Date(), 'YYYY-MM-DD HH:mm'),
        });
      } else {
        this.apiIncident.updates.push({
          description: '',
          reportedAt: format(new Date(), 'YYYY-MM-DD HH:mm'),
        });
      }
    },
    removeUpdate(index: number) {
      this.apiIncident.updates.splice(index, 1);
    },
    deleteIncidentPopUp() {
      this.displayPopUp = !this.displayPopUp;
    },
    async save() {
      this.errorMessage = undefined;
      try {
        await saveApiIncident(this.apiIncidentWithTimezone);
        this.$router.push('/api-incidents');
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data.code === 'ValidationError'
        ) {
          this.errors = error.response.data.errors;

          if (this.errors.updates) {
            // @ts-ignore
            this.updateErrors = this.errors.updates.reduce(
              // @ts-ignore
              (object, item) => {
                const key = Object.keys(item)[0];
                object[key] = item[key];
                return object;
              },
            );
          }
          Vue.nextTick(scrollToError);
        } else if (error && error.response) {
          this.errorMessage = error.response.data.message;
        } else if (error.toString() === 'RangeError: Invalid Date') {
          this.errorMessage =
            'You have entered an invalid date. Please correct it.';
        } else {
          this.errorMessage = 'Something went wrong. Please try again later.';
        }
      }
    },
    async deleteIncident() {
      try {
        await deleteApiIncident(this.apiIncident.id!);
        this.$router.push('/api-incidents');
      } catch (error) {
        if (error && error.response) {
          this.errorMessage = error.response.data.message;
        }
      }
    },
  },
});
