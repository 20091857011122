


















import { ApiDto } from '@portals/shared/admin/ApiDto';
import Vue from 'vue';

import DnbSelect from '@/components/DnbSelect.vue';
import { fetchApis } from '@/service/apiService';

type Option = { value: string | undefined; label: string };

type Data = {
  loading: boolean;
  options: Option[];
};

export default Vue.extend({
  name: 'dnb-api-select',
  components: { DnbSelect },

  props: {
    value: {
      type: [String, Array as () => string[]],
      default: '',
    },
    id: {
      type: String,
      default: undefined,
    },
    validationError: { type: Array, default: undefined },
    help: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
  },
  data(): Data {
    return {
      options: [],
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const response = await fetchApis();
    this.options = [
      ...response.map((api: ApiDto) => ({
        value: api.id,
        label: api.name,
      })),
    ];
    this.loading = false;
  },
  methods: {
    onInput(value: string) {
      this.$emit('input', value);
    },
  },
});
